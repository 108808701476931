import { checkListArray } from 'components/Formik/PasswordField/constants';
import {
    codeLengthMessage,
    insufficientUsernameMessage,
    invalidPhoneNumberFormatMessage,
    inValidUsernameMessage,
    notFoundPhoneNumberMessage,
    phoneNumberAlreadyExistsMessage,
    requiredFieldMessage,
    usernameAlreadyTakenMessage
} from 'constants/messages';
import { onlyPlusAndNumbersRegExp } from 'constants/regularExpressions';
import { FormikErrors, FormikValues } from 'formik';
import { bullzSignEffects } from 'stores/bullzStores/sign';

//TODO: prevent every field validation
interface FormErrors {
    username?: string;
    phone?: string;
    smsCode?: string;
    email?: string;
    password?: string;
    securityCode?: string;
    dateOfBirth?: string;
    userGender?: number;
}

type SingType = 'login' | 'registration' | 'forgotten';

//
/*** Username ***/
//
const validateUsername = async (username: string) => {
    if (!username || username === '@') {
        return requiredFieldMessage;
    }
    if (username[0] !== '@') {
        username = `@${username}`;
    }
    if (username.length < 7) {
        return insufficientUsernameMessage;
    }

    // Formik catches 404 error
    const exists = await bullzSignEffects.checkUsernameExistence(username.slice(1));

    if (exists) {
        return usernameAlreadyTakenMessage;
    } else {
        return '';
    }
};

//
/*** Phone ***/
//
const validatePhone = async (phone: string, countryCode: string) => {
    const regExp = onlyPlusAndNumbersRegExp;
    const regExpCheckResult = regExp.test(countryCode + phone);

    if (!phone) {
        return requiredFieldMessage;
    }

    if (!regExpCheckResult) {
        return invalidPhoneNumberFormatMessage;
    }

    const result = await bullzSignEffects.analyzeMobileNumber(countryCode + phone);

    // 0 = Exists
    // 1 = NotFoundInSystem
    // -2 = PendingVerification
    // -1 = InvalidFormat
    switch (result) {
        case 0:
            return phoneNumberAlreadyExistsMessage;
        case 1:
        case -2:
            return '';
        case -1:
            return invalidPhoneNumberFormatMessage;
        default:
            return requiredFieldMessage;
    }
};

const loginValidatePhone = async (phone: string, countryCode: string) => {
    const regExp = onlyPlusAndNumbersRegExp;
    const regExpCheckResult = regExp.test(countryCode + phone);

    if (!phone) {
        return requiredFieldMessage;
    }

    if (!regExpCheckResult) {
        return invalidPhoneNumberFormatMessage;
    }

    const result = await bullzSignEffects.analyzeMobileNumber(countryCode + phone);

    // 0 = Exists
    // 1 = NotFoundInSystem
    // -2 = PendingVerification
    // -1 = InvalidFormat
    switch (result) {
        case -1:
            return invalidPhoneNumberFormatMessage;
        case 1:
            return notFoundPhoneNumberMessage;
        default:
            return '';
    }
};

//
/*** User name ***/
//
const loginValidateUsername = async (username: string) => {
    if (username) {
        const exists = await bullzSignEffects.checkUsernameExistence(username);
        return exists ? '' : inValidUsernameMessage;
    }

    return requiredFieldMessage;
};

//
/*** Sms Code ***/
//
const validateSmsCode = async (code: string, mobileNumber: string) => {
    if (code?.toString().length !== 6) {
        return codeLengthMessage;
    }

    const isCodeValid = await bullzSignEffects.checkSmsCode({ code, mobileNumber });

    if (isCodeValid === true) {
        return '';
    } else if (isCodeValid === false) {
        return 'Invalid';
    } else return isCodeValid;
};

//
/*** Password ***/
//
const validatePassword = (password: string, signType?: SingType) => {
    if (signType === 'login') {
        return password.length ? '' : requiredFieldMessage;
    }

    for (let item of checkListArray) if (!item.regExp.test(password)) return item.text;
};

//
/*** Main Forms ***/
//
export const validateRegistrationForm = async ({ username, countryCode, phone, smsCode, password }: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.username = await validateUsername(username);
    errors.phone = await validatePhone(phone, countryCode);
    errors.smsCode = await validateSmsCode(smsCode, countryCode + phone);
    errors.password = validatePassword(password);

    return errors;
};

export const validateLoginForm = async ({ countryCode, phone, smsCode, username, password }: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.phone = await loginValidatePhone(phone, countryCode);
    errors.username = await loginValidateUsername(username);
    errors.smsCode = await validateSmsCode(smsCode, countryCode + phone);
    errors.password = validatePassword(password, 'login');

    return errors;
};

export const validatePasswordForgotten = async ({ password, countryCode, phone, smsCode }: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.phone = await loginValidatePhone(phone, countryCode);
    errors.password = await validatePassword(password);
    errors.smsCode = await validateSmsCode(smsCode, countryCode + phone);

    return errors;
};
