import { TopBar } from 'components/common/TopBar';
import { Formik } from 'formik';
import { useSteps } from 'hooks';
import { passwordForgottenInitialProps } from 'pages/BullzPages/Sign/bullzFormikInitialProps';
import { PhoneStep } from 'pages/BullzPages/Sign/PasswordForgotten/Steps/Phone';
import { MainWrapper, StepPageWrapper } from 'pages/BullzPages/Sign/styles';
import React from 'react';
import { BullzPasswordForgottenStepName, BullzPasswordForgottenStepsObject, BullzSignStepProps } from 'types/sign';
import { NewPasswordStep } from './Steps/NewPassword';
import { SmsCodeStep } from './Steps/SmsCode';

const stepsObj: BullzPasswordForgottenStepsObject = {
    PhoneStep,
    SmsCodeStep,
    NewPasswordStep
};

export const BullzPasswordForgottenPage = () => {
    const [CurrentComponent, setCurrentStep, goToPrevStep] = useSteps<
        BullzPasswordForgottenStepName,
        BullzSignStepProps
    >(stepsObj);

    return (
        <Formik {...passwordForgottenInitialProps}>
            <StepPageWrapper>
                {/* <LoaderModal /> */}
                <TopBar onButtonClick={goToPrevStep} />
                <MainWrapper>
                    <CurrentComponent setCurrentStep={setCurrentStep} />
                </MainWrapper>
            </StepPageWrapper>
        </Formik>
    );
};
