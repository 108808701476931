import backgroundImg from 'assets/bullz_welcome_page_bg.png';
import { black } from 'constants/styles/colors';
import styled from 'styled-components';

export const WelcomeWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 80px 40px;
    background-color: ${black};
    background-image: url(${backgroundImg});
    background-position: 26px 75%;
    background-repeat: no-repeat;
    mix-blend-mode: normal;
    background-size: 100%;
`;
