import { bullzBaseURL } from 'constants/stores';
import axios from 'services/axios';
import { isSafari } from 'utils/mobile';
import { getRecordedDate } from 'utils/video';

export const upload = (videoId: string, videoStream: Blob) => {
    const bodyFormData = new FormData();

    // TODO: change name to recorded video time
    const fileName = `${getRecordedDate()}.${isSafari ? 'mp4' : 'webm'}`;
    bodyFormData.append('fileName', new File([videoStream], fileName));

    return axios<BULLZ.MessageResponseBase>({
        url: `/media/upload/${videoId}`,
        baseURL: bullzBaseURL(),
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};
