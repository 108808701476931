import { bullzBaseURL } from 'constants/stores';
import axios from 'services/axios';

export const createAccountAnonymous = (data: BULLZ.UserCreateAnonymousAccountRequest) =>
    axios<BULLZ.UserJwtTokenResponse>(
        {
            url: '/user/create-account-anonymous',
            baseURL: bullzBaseURL(),
            data
        },
        false
    );
