import { bullzBaseURL, womApiVersion, womBaseURL } from 'constants/stores';
import axios from 'services/axios';

export const queryListOfProducts = (data: BULLZ.GetProductsByNameRequest) =>
    axios<BULLZ.GetProductsResponse>({
        url: '/product/query-by-name',
        baseURL: bullzBaseURL(),
        data
    });

export const createProduct = (data: WOM.CreateProductRequest) =>
    axios<WOM.ProductResponse>({
        url: '/product/create',
        baseURL: womBaseURL(),
        data,
        headers: {
            'api-version': womApiVersion
        }
    });
