import { bullzBaseURL } from 'constants/stores';
import axios from 'services/axios';

export const getCategories = (params?: Paths.Categories.Get.QueryParameters) =>
    axios<BULLZ.GetCategoriesResponseApiResponse>({
        url: '/categories',
        baseURL: bullzBaseURL(),
        method: 'GET',
        params
    });
