import { bullzBaseURL } from 'constants/stores';
import axios from 'services/axios';

export const newRequest = (data: BULLZ.CreateVideoRequest) =>
    axios<BULLZ.CreateVideoResponse>({
        url: '/video/new',
        baseURL: bullzBaseURL(),
        //* Values:<br/>0 = None<br/>1 = BullzApp<br/>2 = BullzWebRecorder<br/>3 = BullzCreatorTelegramBot
        data: { ...data, issuerInfo: { source: 2 } }
    });
