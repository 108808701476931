import { PasswordInput } from 'components/common/Input/PasswordInput';
import { StyledInput } from 'components/common/Input/StyledInput';
import { checkListArray } from 'components/Formik/PasswordField/constants';
import { useField } from 'formik';
import React, { FC } from 'react';

interface Props {
    login?: boolean;
    isLoading?: boolean;
    name?: string;
    forgotten?: boolean;
    borderColor?: string;
}

export const PasswordField: FC<Props> = ({ login, isLoading, name = 'password', borderColor }) => {
    const [field, { touched, error }] = useField(name);

    if (login) {
        return (
            <StyledInput
                borderColor={borderColor}
                error={error}
                isLoading={isLoading}
                placeholder="Password"
                touched={touched}
                type="password"
                {...field}
            />
        );
    }

    return <PasswordInput checkListArray={checkListArray} error={error} touched={touched} {...field} />;
};
