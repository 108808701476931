import { createEffect, createEvent, createStore } from 'effector';
import { BullzAPI } from 'services/bullzServices';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { Steps, stepsEvents } from 'stores/steps';
import { createNotifyingEffect } from 'utils/common';

//*** events *****

const setOrganizationPublicId = createEvent<string>('');
const setInitialRemoteProductValues = createEvent<WOM.RemoteProductDescription>();
const setRemoteProductValues = createEvent<WOM.RemoteProductDescription>();
const setErrors = createEvent<boolean>();

//*** effects *****

const queryListOfProducts = createNotifyingEffect({
    handler: async (name: string) =>
        await BullzAPI.product.queryListOfProducts({
            name,
            limit: 5,
            returnQueryCount: true
        })
});

const createProduct = createNotifyingEffect({
    handler: async (name: string) =>
        await BullzAPI.product.createProduct({
            name
            // upc: ''
        })
});

//*** stores *****

const organizationPublicId = createStore<string>('').on(setOrganizationPublicId, (_, state) => state);

const initialRemoteProductValues = createStore<WOM.RemoteProductDescription>({}).on(
    setInitialRemoteProductValues,
    (_, { ...newValues }) => newValues
);

const remoteProductValues = createStore<WOM.RemoteProductDescription>({}).on(
    setRemoteProductValues,
    (state, { ...newValues }) => ({ ...state, ...newValues })
);

const initializeRemoteProduct = createEffect({
    handler: async () => {
        try {
            const Id = organizationPublicId.getState();
            const remoteProduct = remoteProductValues.getState();

            const response = await BullzAPI.womServices.product.initializeRemoteProduct({
                organizationPublicId: Id,
                remoteProduct
            });

            return response;
        } catch (error) {
            let message = error.message;

            if (!message || message === 'unknown') {
                message = 'Some error occurred. Please try again.';
            }
            modalEvents.openAsyncModal({
                title: 'Error',
                errorText: message,
                onOk: () => stepsEvents.setStep(Steps.BullzWelcome)
            });

            setErrors(true);
            return {};
        }
    }
});

const productId = createStore('').on(initializeRemoteProduct.doneData, (_, { productId }) => productId);

const productErrors = createStore(false).on(setErrors, (_, newState) => newState);

export const bullzProductEvents = { setInitialRemoteProductValues, setRemoteProductValues, setOrganizationPublicId };

export const bullzProductEffects = {
    queryListOfProducts,
    initializeRemoteProduct,
    createProduct
};
export const bullzProductStores = {
    initialRemoteProductValues,
    remoteProductValues,
    organizationPublicId,
    productId,
    productErrors
};
