import { inputStyleMixin } from 'components/common/Input/styles';
import { black, errorColor, grey, violet } from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { BorderColor } from 'types/styles';

const borderStyleMixin = css`
    border: none;
    ${inputStyleMixin};
    border-bottom: 2px solid;
    border-color: ${grey};

    :focus {
        border-color: ${black};
    }

    font-size: 16px;
    border-radius: 0;
    height: auto;
    padding-left: 10px;
`;

export const PhoneInputWrapper = styled.div<BorderColor>`
    width: 100%;
    .react-tel-input {
        font-family: inherit;
        font-size: 16px;
        position: initial;
        display: flex;
        flex-direction: row-reverse;
    }

    .react-tel-input .flag-dropdown {
        position: initial;
        border-radius: 0px;
    }
    .phone-input {
        ${borderStyleMixin}
        &_error {
            ${borderStyleMixin}
            border-color: ${errorColor};
        }
        &_success {
            ${borderStyleMixin}
            border-color:  ${({ borderColor }) => borderColor || violet};
        }
    }

    .country-code-container {
        width: 130px;
        margin-right: 8px;
        border: none;
        border-bottom: 2px solid ${black};
        background: none;

        /* &_error {
            border-color: ${errorColor};
        } */
        &_success {
            border-color: ${({ borderColor }) => borderColor || violet};
        }

        .country-list .country {
            display: flex;
        }
        .selected-flag {
            width: 100%;
        }
        .flag {
            display: flex;
            align-items: center;
            width: 16px !important;
            .arrow {
                position: static;
                margin-top: 0;
            }
            .country-code {
                margin-left: 16px;
                padding: 0 6px;
                font-size: 16px;
            }
        }
    }
    .country-code {
        word-break: keep-all;
    }
`;
