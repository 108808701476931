import { createEffect, createStore } from 'effector';
import { BullzAPI } from 'services/bullzServices';

//Effects

const getCategoriesFx = createEffect({
    handler: async () => await BullzAPI.categories.getCategories({ limit: 100 })
});

//Stores

const $categories = createStore<BULLZ.GetCategoriesResponse>({}).on(
    getCategoriesFx.doneData,
    (_, newState) => newState.data
);

//Exports

export const categoriesStores = { $categories };

export const categoriesEffects = {
    getCategoriesFx
};
