import preloaderSvgBullz from 'assets/preloader_bullz.svg';
import { Button } from 'components/common/buttons/Button';
import { CustomImg } from 'components/common/CustomImg';
import { Select } from 'components/common/grid/Selector';
import { Label } from 'components/common/Input/WrappedInput/styles';
import { UnderlinedField } from 'components/Formik/UnderlinedField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { languagesArray } from 'constants/languages';
import { bullzBaseURL } from 'constants/stores';
import { violet, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import { HashtagsWrapper, HashtagWrapper } from 'pages/BullzPages/Upload/Hashtags/styles';
import { StickyPositionWrapper } from 'pages/BullzPages/Upload/Steps/Form/styles';
import { Title } from 'pages/BullzPages/Upload/styles';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { categoriesEffects, categoriesStores } from 'stores/bullzStores/categories';
import { bullzProductEffects, bullzProductEvents, bullzProductStores } from 'stores/bullzStores/product';
import { bullzSignStores, TypeOfUser } from 'stores/bullzStores/sign';
import { bullzStores } from 'stores/bullzStores/user';
import { bullzVideoEffects, bullzVideoEvents, bullzVideoStores } from 'stores/bullzStores/video';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { Steps, stepsEvents } from 'stores/steps';
import * as tus from 'tus-js-client';
import { UploadStepProps } from 'types/upload';
import { getLocaleFromValue, uuid } from 'utils/common';
import { isSafari } from 'utils/mobile';
import { getRecordedDate } from 'utils/video';

const { updateNewVideoRequestValues } = bullzVideoEvents;
const { invokeNewVideoRequest } = bullzVideoEffects;
const { videoErrors, recorderVideo, recorderVideoScreen } = bullzVideoStores;

const { setRemoteProductValues } = bullzProductEvents;
const { initializeRemoteProduct } = bullzProductEffects;
const { remoteProductValues, productErrors } = bullzProductStores;
const { setStep } = stepsEvents;
const { openUploadProgressModal, closeUploadProgressModal, updateUploadProgressModal } = modalEvents;

export const FormStep: FC<UploadStepProps> = ({ setCurrentStep }) => {
    const [{ value: brandValue }, { error: brandError }, { setTouched: setBrandTouched }] = useField('brand');
    const [{ value: productValue }, { error: productError }, { setTouched: setProductTouched }] = useField('product');
    const [{ value: productLinkValue }, { error: productLinkError }, { setTouched: setProductLinkTouched }] = useField(
        'productLink'
    );
    const [{ value: languageValue }] = useField('language');
    const [{ value: categoryValue }] = useField('categoryTagId');

    const remoteProduct = useStore(remoteProductValues);
    const initializeRemoteProductError = useStore(productErrors);
    const newVideoRequestError = useStore(videoErrors);

    const categoriesIsLoading = useStore(categoriesEffects.getCategoriesFx.pending);
    const categoriesResponse = useStore(categoriesStores.$categories);
    const categories = (categoriesResponse.items || []).map(i => i.tagId).filter(i => !!i) as string[];
    const categoriesItems = categories.length > 0 ? categories : ['wallets'];

    const [isBrandFieldDisabled, setIsBrandFieldDisabled] = useState(true);
    const [isProductValueFieldDisabled, setIsProductValueFieldDisabled] = useState(true);
    const [isProductLinkFieldDisabled, setIsProductLinkFieldDisabled] = useState(true);

    const [isSuccessfulTusVideoUpload, setIsSuccessfulTusVideoUpload] = useState(false);

    const typeOfUser = useStore(bullzSignStores.typeOfUser);
    const isWithoutAccount = typeOfUser === TypeOfUser.withoutAuthorization;

    const videoBlob = useStore(bullzVideoStores.recorderVideo);
    const [uploadSessionId, setUploadSessionId] = useState('');
    const token = useStore(bullzStores.token);
    const recordedVideo = useStore(recorderVideo);
    const fileName = `${getRecordedDate()}.${isSafari ? 'mp4' : 'webm'}`;
    const recordedVideoScreen = useStore(recorderVideoScreen);

    const textDoneButton = isWithoutAccount ? (!isSuccessfulTusVideoUpload ? 'Uploading' : 'Upload') : 'Done';

    const error = brandError || productError || productLinkError;

    const uploadTus = useCallback(async () => {
        if (!videoBlob || !recordedVideo) {
            throw new Error();
        }
        openUploadProgressModal();
        const newUploadSessionId = uuid();
        setUploadSessionId(newUploadSessionId);

        const upload = new tus.Upload(recordedVideo, {
            endpoint: bullzBaseURL() + 'media/upload-v2',
            retryDelays: [0, 3000, 5000, 10000, 20000],
            headers: {
                Authorization: `Bearer ${token}`,
                'api-version': '2',
                uploadId: '000000000000000000000000',
                uploadSessionId: newUploadSessionId,
                isAsync: 'true'
            },
            metadata: {
                filename: fileName,
                filetype: recordedVideo.type,
                contentType: 'video/mp4',
                uploadId: '000000000000000000000000',
                uploadSessionId: newUploadSessionId,
                isAsync: 'true'
            },
            onError: function (error) {
                console.log('Failed because of: ' + error);

                modalEvents.openAsyncModal({
                    visible: true,
                    title: 'Upload Error',
                    errorText: error.message,
                    okText: 'Try again',
                    onOk: () => {
                        setStep(Steps.VideoRecord);
                    }
                });
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                updateUploadProgressModal({
                    progress: +percentage,
                    uploaded: false,
                    poster: recordedVideoScreen
                });
            },
            onSuccess: function () {
                setIsSuccessfulTusVideoUpload(true);

                updateUploadProgressModal({
                    uploaded: true
                });
            }
        });

        upload.findPreviousUploads().then(function (previousUploads) {
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0]);
            }

            upload.start();
        });
    }, [recordedVideoScreen, fileName, recordedVideo, token, videoBlob]);

    const updateData = async () => {
        //* if not set in init parameters or changed/set on this(Form) step
        remoteProduct?.name !== productValue &&
            setRemoteProductValues({
                name: productValue
            });
        //* if not set in init parameters or changed/set on this(Form) step
        remoteProduct?.brand !== brandValue &&
            setRemoteProductValues({
                brand: brandValue
            });

        remoteProduct?.url !== productLinkValue &&
            setRemoteProductValues({
                url: productLinkValue
            });

        //* initializing remote product with data from init parameters ( organizationPublicId,remoteProduct)
        await initializeRemoteProduct();

        //* productId adds to "newVideoRequestValues" in video store after initializeRemoteProduct response was received?
        updateNewVideoRequestValues({
            categoryTagId: categoryValue,
            brand: brandValue,
            product: productValue,
            uploadSessionId: uploadSessionId,
            spokenLanguages: [getLocaleFromValue(languageValue)],
            productLink: productLinkValue
        });

        if (isWithoutAccount) {
            await invokeNewVideoRequest();
        }
    };

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (error) {
            return;
        }

        await updateData();

        closeUploadProgressModal();

        if (!initializeRemoteProductError && !newVideoRequestError) {
            isWithoutAccount ? setStep(Steps.BullzThankYou) : setCurrentStep('FinalStep');
        }
    };

    useEffect(() => {
        setIsBrandFieldDisabled(!!remoteProduct?.brand);
        setIsProductValueFieldDisabled(!!remoteProduct?.name);
        setIsProductLinkFieldDisabled(!!remoteProduct?.url);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteProduct]);

    useEffect(() => {
        brandValue && setBrandTouched(true, true);
        productValue && setProductTouched(true, true);
        productLinkValue && setProductLinkTouched(true, true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandValue, productValue, productLinkValue]);

    useEffect(() => {
        if (recordedVideoScreen && !uploadSessionId) {
            uploadTus();
        }
    }, [recordedVideoScreen, uploadSessionId, uploadTus]);

    useEffect(() => {
        categoriesEffects.getCategoriesFx();
    }, []);

    if (categoriesIsLoading) {
        return (
            <Section justifyCenter>
                <CustomImg height="32px" src={preloaderSvgBullz} width="32px" />
            </Section>
        );
    }

    return (
        <>
            <Title>What are you recommending?</Title>
            <Form onSubmit={handleFormSubmit}>
                <Section>
                    <Select
                        defaultValue={categoriesItems[0]}
                        label="Category"
                        name="categoryTagId"
                        values={categoriesItems}
                    />
                </Section>

                <UnderlinedField
                    borderColor={violet}
                    disabled={isBrandFieldDisabled}
                    label="BRAND"
                    name="brand"
                    placeholder="e.g. ConsenSys"
                />
                <UnderlinedField
                    borderColor={violet}
                    disabled={isProductValueFieldDisabled}
                    label="TOPIC"
                    name="product"
                    placeholder="e.g. Metamask"
                />
                <UnderlinedField
                    borderColor={violet}
                    disabled={isProductLinkFieldDisabled}
                    label="Website URl"
                    name="productLink"
                    placeholder="https://binance.com"
                />

                <Column>
                    <Section marginBottom="10px">
                        <Label>Your Tags</Label>
                    </Section>
                    <HashtagsWrapper>
                        <HashtagWrapper backgroundColor={violet} color={white}>
                            #{categoryValue}
                        </HashtagWrapper>
                        <HashtagWrapper backgroundColor={violet} color={white}>
                            #{brandValue}
                        </HashtagWrapper>
                        <HashtagWrapper backgroundColor={violet} color={white}>
                            #{productValue}
                        </HashtagWrapper>
                    </HashtagsWrapper>
                </Column>

                <Section marginTop="20px">
                    <Select label="Spoken Language" name="language" values={languagesArray} />
                </Section>

                <StickyPositionWrapper>
                    <Button backgroundColor={error && '#E3E3E3'} disabled={!!error}>
                        {textDoneButton}
                    </Button>
                </StickyPositionWrapper>
            </Form>
        </>
    );
};
