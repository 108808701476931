import { TopBar } from 'components/common/TopBar';
import { Column } from 'components/wrappers/FlexWrapper';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import { useSteps } from 'hooks';
import { FinalStep } from 'pages/BullzPages/Upload/Steps/Final';
import { FormStep } from 'pages/BullzPages/Upload/Steps/Form';
import {
    MainWrapper,
    VideoDurationText,
    VideoDurationTextBlock,
    VideoInfoWrapper,
    VideoName,
    VideoPoster
} from 'pages/BullzPages/Upload/styles';
import { validateUploadForm } from 'pages/BullzPages/Upload/validation';
import React, { FC } from 'react';
import { bullzProductStores } from 'stores/bullzStores/product';
import { bullzVideoStores } from 'stores/bullzStores/video';
import { UploadStepName, UploadStepProps, UploadStepsObject } from 'types/upload';
import { uploadInitialProps } from './formikInitialProps';

const { remoteProductValues } = bullzProductStores;
const { recorderVideoScreen, recorderVideoDuration, recorderVideoDate } = bullzVideoStores;

const stepsObj: UploadStepsObject = {
    FormStep,
    FinalStep
};

export const BullzUploadPage: FC = () => {
    const { name: product, brand, url: productLink } = useStore(remoteProductValues);
    const [CurrentComponent, setCurrentStep, goToPrevStep, currentStep] = useSteps<UploadStepName, UploadStepProps>(
        stepsObj
    );
    const { initialErrors, initialValues } = uploadInitialProps;
    const duration = useStore(recorderVideoDuration);
    const recordedVideoScreen = useStore(recorderVideoScreen);
    const recordedVideoDate = useStore(recorderVideoDate);

    return (
        <Formik
            enableReinitialize
            {...uploadInitialProps}
            initialErrors={{
                ...initialErrors,
                ...validateUploadForm({ product, brand, productLink })
            }}
            initialValues={{
                ...initialValues,
                product: product || undefined,
                brand: brand || undefined,
                productLink: productLink || undefined
            }}
        >
            <>
                {/* <></> - need for formik */}
                {currentStep !== 'FinalStep' && <TopBar onButtonClick={goToPrevStep} />}
                <MainWrapper isFinalStep={currentStep === 'FinalStep'}>
                    <VideoInfoWrapper>
                        <VideoPoster poster={recordedVideoScreen} />
                        <Column>
                            <VideoName>{recordedVideoDate}.mov</VideoName>
                            <VideoDurationTextBlock>
                                <VideoDurationText>Duration</VideoDurationText> {Number(duration).toFixed(2)} seconds
                            </VideoDurationTextBlock>
                        </Column>
                    </VideoInfoWrapper>
                    <CurrentComponent goToPrevStep={goToPrevStep} setCurrentStep={setCurrentStep} />
                </MainWrapper>
            </>
        </Formik>
    );
};
