export const title = 'Video Upload Complete';

export const withoutAccountMessage = 'Your video has been uploaded.';
export const singUpMessage =
    'Your video has been uploaded. Please download BULLZ app on your phone and submit the video for validation to earn rewards.';
export const logInMessage = 'Your video has been uploaded. Go and check your BULLZ app.';

export const buttonText = 'Okay';

export const titleFontSize = '18px';
export const messageFontSize = '16px';
export const messageLineHeight = '20px';
export const textFontWeight = '600';
export const messageMargin = '25px 0px 30px';
