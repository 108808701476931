import { useStore } from 'effector-react';
import { logInMessage, singUpMessage, title, withoutAccountMessage } from 'pages/BullzPages/ThankYou/constants';
import { PageBackground, Wrapper } from 'pages/BullzPages/ThankYou/styles';
import React, { useEffect } from 'react';
import { bullzProductEvents, bullzProductStores } from 'stores/bullzStores/product';
import { bullzSignStores, TypeOfUser } from 'stores/bullzStores/sign';
import { bullzVideoEvents } from 'stores/bullzStores/video';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { Steps, stepsEvents } from 'stores/steps';
import { ApplicationInitialStyles } from 'types/plugin';

const { initialRemoteProductValues } = bullzProductStores;
const { setRemoteProductValues } = bullzProductEvents;
const { updateNewVideoRequestValues } = bullzVideoEvents;

export const BullzThankYouPage = ({ background }: ApplicationInitialStyles) => {
    const initialProductValues = useStore(initialRemoteProductValues);
    const typeOfUser = useStore(bullzSignStores.typeOfUser);

    /* Status of user */
    const isAuthorizeWithoutAccount = typeOfUser === TypeOfUser.withoutAuthorization;
    const isWithoutWallet = typeOfUser === TypeOfUser.withoutWallet;
    const message = isAuthorizeWithoutAccount ? withoutAccountMessage : isWithoutWallet ? singUpMessage : logInMessage;

    const resetValues = () => {
        const { brand, name, publicId, url, imageUrl } = initialProductValues;

        //* to reset values that were set manually during previous upload
        //* productId adds to "newVideoRequestValues" in video store after initializeRemoteProduct response was received
        updateNewVideoRequestValues({
            brand: brand || '',
            product: name || '',
            productId: '',
            hashTags: [],
            spokenLanguages: [],
            uploadSessionId: '',
            productLink: url || ''
        });
        setRemoteProductValues({
            brand: brand || '',
            name: name || '',
            publicId: publicId || '',
            url: url || '',
            imageUrl: imageUrl || ''
        });
    };

    const goToStart = () => {
        resetValues();
        stepsEvents.setStep(Steps.VideoRecord);
    };

    useEffect(() => {
        modalEvents.openAsyncModal({
            title: title,
            successText: message,
            onOk: goToStart
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <PageBackground background={background} />
        </Wrapper>
    );
};
