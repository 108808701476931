import { StyledButtonProps } from 'components/common/buttons/Button/types';
import { black, grey, violet, white } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import { Loading } from 'types/styles';

interface Props extends StyledButtonProps, Loading {}

export const StyledButton = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    width: ${({ width }) => width || '100%'};
    max-width: 330px;
    height: ${({ height }) => height || '48px'};
    font-size: ${({ fontSize }) => fontSize || '15px'};
    font-weight: ${({ fontWeight }) => fontWeight || '700'};
    line-height: 0;
    color: ${({ color }) => color || white};
    background-color: ${({ backgroundColor }) => backgroundColor || violet};
    border: 3px solid ${({ borderColor, backgroundColor }) => borderColor || backgroundColor || violet};
    border-radius: ${({ borderRadius }) => borderRadius || '24px'};

    cursor: pointer;
    pointer-events: ${({ isLoading }) => isLoading && 'none'};

    :hover {
        opacity: 0.8;
    }

    :disabled {
        background-color: ${grey};
        border: 0;
        cursor: not-allowed;
        color: ${black};
    }
`;
