import { createEffect, createEvent, createStore } from 'effector';
import { BullzAPI } from 'services/bullzServices';

/**
 * Effect to load JWT token for anonymous user
 */
const loadToken = createEffect({
    handler: async () => {
        const { token } = await BullzAPI.user.createAccountAnonymous({ locale: 'en_EN' });

        return token || '';
    }
});

const setUser = createEvent<BULLZ.GetUserResponse>();
const user = createStore<BULLZ.GetUserResponse>({ userId: '' }).on(setUser, (_, user: BULLZ.GetUserResponse) => user);

const setToken = createEvent<string>();
const token = createStore('')
    .on(loadToken.doneData, (_, token) => token)
    .on(setToken, (_, token) => token);

const bullzUserEffects = { loadToken, setUser, setToken };
const bullzStores = { user, token };

export { bullzUserEffects, bullzStores };
