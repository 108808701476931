import styled from 'styled-components';

export const MainWrapper = styled.section`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 28px 0 0 /*55px*/;
    /* flex-grow: 1; */
`;

export const Title = styled.h1`
    font-size: 23px;
    margin-bottom: 66px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
    flex-grow: 1; */
`;

export const Subtitle = styled.p`
    font-size: 16px;
    font-weight: 700;
`;

export const Link = styled.a`
    text-decoration: underline;
`;

export const Text = styled.p`
    font-size: 12px;
    margin: 100px 0 92px;
`;

export const ButtonWrapper = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
`;
