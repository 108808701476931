import { violet, white } from 'constants/styles/colors';
import styled from 'styled-components';

export const ProgressModalBody = styled.div`
    max-width: 335px;
    width: 100%;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    height: 97px;
    padding: 12px;
    background-color: ${white};
    filter: drop-shadow(12px 12px 24px rgba(0, 0, 0, 0.08));
    display: flex;
    align-items: center;
    border-radius: 15px;
`;

export const ProgressModalPoster = styled.img`
    height: 72px;
    width: 63px;
    object-fit: cover;
    border-radius: 10px;
`;

interface ProgressModalLineProps {
    progress: number;
}

export const ProgressModalLine = styled.div<ProgressModalLineProps>`
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 100px;
    background-color: #f5f5f5;
    margin-top: 15px;
    overflow: hidden;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        background-color: ${violet};
        width: ${({ progress }) => progress}%;
    }
`;

export const UploadProgressIcon = styled.img`
    margin-right: 9px;
`;
