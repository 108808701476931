import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { black, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import {
    minorTextFontSize,
    subtitleMarginBottom,
    titleMarginBottom
} from 'pages/BullzPages/Upload/Steps/Final/constants';
import {
    ButtonsWrapper,
    CancelButton,
    ContentWrapper,
    DarkenBg,
    MainWrapper,
    Qualities,
    Stake,
    StyledButton,
    Text,
    Title
} from 'pages/BullzPages/Upload/Steps/Final/styles';
import React, { FC, useState } from 'react';
import { bullzSignStores, TypeOfUser } from 'stores/bullzStores/sign';
import { bullzVideoEffects, bullzVideoStores } from 'stores/bullzStores/video';
import { Steps, stepsEvents } from 'stores/steps';
import { UploadStepProps } from 'types/upload';

type LoadingStatus = '' | 'Submit in progress' | 'Upload in progress';

const { validateContent, invokeNewVideoRequest } = bullzVideoEffects;
const { recorderVideoScreen } = bullzVideoStores;
const { setStep } = stepsEvents;

export const FinalStep: FC<UploadStepProps> = ({ goToPrevStep }) => {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('');
    const videoScreen = useStore(recorderVideoScreen);
    const typeOfUser = useStore(bullzSignStores.typeOfUser);

    /* Status of user */
    const isWithoutWallet = typeOfUser === TypeOfUser.withoutWallet;
    const isWithoutAccount = typeOfUser === TypeOfUser.withoutAuthorization;
    const hasFreeStakes = typeOfUser === TypeOfUser.hasFreeStakes;
    const isNoFreeStakeAndZeroWOMBalance = typeOfUser === TypeOfUser.withoutFreeStakesAndZeroWomBalance;
    const isNoFreeStakeAndPositiveWOMBalance = typeOfUser === TypeOfUser.withoutFreeStakesAndPositiveWomBalance;
    const isUploadOnly = isWithoutAccount || isWithoutWallet || isNoFreeStakeAndZeroWOMBalance;

    const title = isWithoutAccount || isWithoutWallet ? 'Upload Video' : 'Submit Video';

    const goToThankYouStep = () => {
        setStep(Steps.BullzThankYou);
    };

    const handleUpload = async () => {
        setLoadingStatus('Upload in progress');

        const { videoId } = await invokeNewVideoRequest();

        if (videoId) {
            setLoadingStatus('');
            goToThankYouStep();
        }
    };

    const submitVideo = async () => {
        const { videoId } = await invokeNewVideoRequest();

        if (videoId) {
            const validationResponse = await validateContent({
                contentId: videoId,
                stakeAmount: 1, //* 0
                requestStakingPromotion: !isNoFreeStakeAndPositiveWOMBalance, //*  false
                holdUntilApproved: true
            });

            if (validationResponse) {
                setLoadingStatus('');
                goToThankYouStep();
            }
        }
    };

    const handleSubmit = async () => {
        setLoadingStatus('Submit in progress');

        await submitVideo();
    };

    return (
        <MainWrapper poster={videoScreen}>
            <DarkenBg />
            <ContentWrapper>
                <MarginWrapper marginBottom="40px">
                    <Title>{title}</Title>
                </MarginWrapper>
                {isWithoutAccount || isWithoutWallet ? (
                    <Text>
                        You must install the BULLZ app and login using the same credentials you used to register. Once
                        logged in, you will need to create a WOM Wallet and then submit your video for approval.
                    </Text>
                ) : (
                    <>
                        <MarginWrapper marginBottom={titleMarginBottom}>
                            <Text>
                                In order to earn rewards you must stake WOM. When you stake, your video will be reviewed
                                by the WOM Authenticators and rated according to the following metrics:
                            </Text>
                        </MarginWrapper>
                        <MarginWrapper marginBottom={titleMarginBottom}>
                            <Qualities>
                                <p>Honesty</p>
                                <p>Creativity</p>
                                <p>Positivity</p>
                            </Qualities>
                        </MarginWrapper>
                        <MarginWrapper marginBottom={subtitleMarginBottom}>
                            <Text fontSize={minorTextFontSize}>This review requires a stake of</Text>
                        </MarginWrapper>
                        <MarginWrapper marginBottom={subtitleMarginBottom}>
                            <Stake>1.0</Stake>
                        </MarginWrapper>

                        {hasFreeStakes && <Text fontSize={minorTextFontSize}>This one is on us</Text>}

                        {isNoFreeStakeAndZeroWOMBalance && (
                            <Text>You will need to log into the Bullz app and buy more WOM in order to submit.</Text>
                        )}
                    </>
                )}
            </ContentWrapper>
            <ButtonsWrapper>
                {isUploadOnly ? (
                    <StyledButton
                        backgroundColor={white}
                        color={black}
                        isLoading={loadingStatus === 'Upload in progress'}
                        onClick={handleUpload}
                    >
                        Upload only
                    </StyledButton>
                ) : (
                    <StyledButton
                        backgroundColor={white}
                        color={black}
                        isLoading={loadingStatus === 'Submit in progress'}
                        onClick={handleSubmit}
                    >
                        Submit
                    </StyledButton>
                )}
                <CancelButton backgroundColor="transparent" color="rgba(255,255,255,0.5)" onClick={goToPrevStep}>
                    Cancel
                </CancelButton>
            </ButtonsWrapper>
        </MainWrapper>
    );
};
