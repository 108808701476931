import { LoginInputType } from 'components/common/grid/InputSelector/constants';
import { userStorageName } from 'constants/storage';
import { createEffect, createEvent, createStore, restore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { BullzAPI } from 'services/bullzServices';
import { bullzUserEffects } from 'stores/bullzStores/user';
import { Steps, stepsEvents } from 'stores/steps';
import { createNotifyingEffect } from 'utils/common';

//
/*** Username ***/
//
const checkUsernameExistence = createNotifyingEffect({
    handler: async (username: string) => {
        const { exists } = await BullzAPI.sign.checkUsernameExistence({ username });

        return exists;
    }
});

//
/*** Chosen Registration Input ***/
//
const changeChosenSignInput = createEvent<LoginInputType>();
const chosenSignInput = createStore<LoginInputType>('Phone').on(changeChosenSignInput, (_, chosenInput) => chosenInput);

//
/*** Mobile Number ***/
//
const analyzeMobileNumber = createNotifyingEffect({
    handler: async (mobileNumber: string) => {
        const { result } = await BullzAPI.sign.analyzeMobileNumber({ mobileNumber });

        return result;
    }
});

//
/*** Sms Code ***/
//
const sendSmsCode = createNotifyingEffect({
    handler: async (mobileNumber: string) => {
        await BullzAPI.sign.sendVerificationSms({ mobileNumber });
    }
});

const sendForgottenPasswordSmsCode = createNotifyingEffect({
    handler: async (mobileNumber: string) => {
        try {
            const { isOk } = await BullzAPI.sign.sendSmsForgottenPassword({ mobileNumber });

            return isOk;
        } catch (e) {
            return e.message;
        }
    }
});

const checkSmsCode = createEffect({
    handler: async (data: BULLZ.CheckSmsCodeRequest) => {
        try {
            const { result } = await BullzAPI.sign.checkSmsCode(data);

            return result;
        } catch (e) {
            return e.message;
        }
    }
});

//
/*** Forgotten Password ***/
//
const setNewPassword = createEffect({
    handler: async (data: BULLZ.ChangeUserPasswordViaSmsRequest) =>
        await BullzAPI.sign.setNewPassword(data).catch(err => err)
});

//
/*** Auth ***/
//
const setIsSubmitButtonShowed = createEvent<boolean>();
const isSubmitButtonShowed = restore(setIsSubmitButtonShowed, false);

//
/*** Authorization Without Account ***/
//

const authorizeWithoutAccount = createNotifyingEffect({
    handler: async () => {
        const response = await BullzAPI.sign.authenticateUserWithoutAccount({});

        if (response.token) {
            bullzUserEffects.setToken(response.token);
            stepsEvents.setStep(Steps.BullzUpload);
        }
        return response;
    }
});

//
/*** Wallet ***/
//

const getWalletByToken = createEffect({
    handler: async () => {
        try {
            return await BullzAPI.womServices.wallet.getWallet({});
        } catch {
            return {};
        }
    }
});

const wallet = createStore<WOM.WalletResponse>({}).on(getWalletByToken.doneData, (_, wallet) => wallet);

export enum TypeOfUser {
    Undefined,
    withoutAuthorization,
    withoutWallet,
    hasFreeStakes,
    withoutFreeStakesAndPositiveWomBalance,
    withoutFreeStakesAndZeroWomBalance
}

//
/*** Logic for set Type of User ***/
/*** If user choose "Continue without account" - type of user = withoutAuthorization ***/
/*** If user logged by username or mobile-phone: ***/
/***    if hasWallet from Wallet of user is true: ***/
/***        if freeStakesRemaining value of user not equal 0: ***/
/***            type of user = hasFreeStakes. ***/
/***        if balance of Wallet not equal 0: ***/
/***            type of user = withoutFreeStakesAndPositiveWomBalance. ***/
/***        if balance of Wallet not equal 0: ***/
/***            type of user = withoutFreeStakesAndZeroWomBalance. ***/
/***    if hasWallet from Wallet of user is false: ***/
/***        type of user = withoutWallet. ***/
/*** If user choose "Continue without account": ***/
/***    type of user = withoutAuthorization. ***/
//

const getTypeOfUserByUserProfile = createEffect({
    handler: async (user: BULLZ.UserJwtTokenResponse) => {
        await getWalletByToken();
        const walletData = wallet.getState();
        const { hasWallet } = walletData;
        if (hasWallet) {
            if (user.user?.freeStakesRemaining) {
                return TypeOfUser.hasFreeStakes;
            } else {
                const balance = walletData.items?.[0]?.balance || 0;

                if (balance > 0) {
                    return TypeOfUser.withoutFreeStakesAndPositiveWomBalance;
                } else {
                    return TypeOfUser.withoutFreeStakesAndZeroWomBalance;
                }
            }
        } else {
            return TypeOfUser.withoutWallet;
        }
    }
});

const typeOfUser = createStore<TypeOfUser>(TypeOfUser.Undefined)
    .on(authorizeWithoutAccount.doneData, () => TypeOfUser.withoutAuthorization)
    .on(getTypeOfUserByUserProfile.doneData, (_, newTypeOfUser) => newTypeOfUser);

interface AuthenticateUserData extends BULLZ.UserAuthChallengeEmailOrUsernameOrPhoneRequest {
    errorForPasswordInput?: (errorMessage: string) => void;
}

const authenticateUser = createNotifyingEffect({
    handler: async (data: AuthenticateUserData) => {
        try {
            const response = await BullzAPI.sign.authenticateUser(data).then(err => err);

            const { token, user } = response;

            token && bullzUserEffects.setToken(token);

            if (user) {
                bullzUserEffects.setUser(user);

                if (user.isAccountVerified && !!user.profile?.mobileNumber) {
                    stepsEvents.setStep(Steps.BullzUpload);
                }
            }
            return response;
        } catch (e) {
            const { message }: BULLZ.Error404NotFoundResponse = e;
            if (data.errorForPasswordInput) data.errorForPasswordInput(message || 'Oops, Login failed');
        }
    }
});
const userStorage = connectLocalStorage(userStorageName).onError(err => console.log(err));
const user = createStore<BULLZ.UserJwtTokenResponse>(userStorage.init({})).on(
    authenticateUser.doneData,
    (_, newState) => newState
);
user.watch(userStorage);
user.updates.watch(state => setIsSubmitButtonShowed(!!state?.user?.freeStakesRemaining));
user.updates.watch(state => getTypeOfUserByUserProfile(state));

//
/*** Creating Account ***/
//
const createAccount = createNotifyingEffect({
    handler: async (data: BULLZ.UserCreateAccountRequest) => await BullzAPI.sign.createAccount(data)
});

//
/*** Get Current Authorization ***/
//
const getCurrentAuthorization = createNotifyingEffect({
    handler: async (userId: string) => await BullzAPI.sign.getCurrentAuthorization({ userId })
});

//
/*** Exports ***/
//
export const bullzSignEffects = {
    checkUsernameExistence,
    analyzeMobileNumber,
    sendSmsCode,
    sendForgottenPasswordSmsCode,
    checkSmsCode,
    setNewPassword,
    authenticateUser,
    createAccount,
    getCurrentAuthorization,
    authorizeWithoutAccount
};

export const bullzSignEvents = { changeChosenSignInput };

export const bullzSignStores = { chosenSignInput, user, isSubmitButtonShowed, typeOfUser };
