import { bullzBaseURL } from 'constants/stores';
import axios from 'services/axios';

export const createWOMWallet = () =>
    axios<BULLZ.MessageResponseBase>({
        url: '/wom/create-wom-wallet',
        baseURL: bullzBaseURL()
    });

export const beginValidation = (data: BULLZ.WOMStartValidationRequest) =>
    axios<BULLZ.ValidationStateResponse>({
        url: '/wom/begin-validation',
        baseURL: bullzBaseURL(),
        data
    });
