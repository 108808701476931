import { InformationFooter } from 'components/common/blocks/RegistrationFooter';
import { LinkButton } from 'components/common/buttons/LinkButton';
import { TopBar } from 'components/common/TopBar';
import { SuccessModal } from 'components/modals/SuccessModal';
import { Formik } from 'formik';
import { useSteps } from 'hooks';
import { registrationInitialProps } from 'pages/BullzPages/Sign/bullzFormikInitialProps';
import { PhoneStep } from 'pages/BullzPages/Sign/Registration/Steps/Phone';
import { MainWrapper, StepPageWrapper } from 'pages/BullzPages/Sign/styles';
import React from 'react';
import { Steps, stepsEvents } from 'stores/steps';
import { BullzRegistrationStepName, BullzRegistrationStepsObject, BullzSignStepProps } from 'types/sign';
import { AgreementStep } from './Steps/Agreement';
import { PasswordStep } from './Steps/Password';
import { SmsCodeStep } from './Steps/SmsCode';
import { UsernameStep } from './Steps/Username';

const stepsObj: BullzRegistrationStepsObject = {
    PhoneStep,
    UsernameStep,
    PasswordStep,
    SmsCodeStep,
    AgreementStep
};

export const BullzRegistrationPage = () => {
    const [CurrentComponent, setCurrentStep, goToPrevStep, currentStep] = useSteps<
        BullzRegistrationStepName,
        BullzSignStepProps
    >(stepsObj);

    const noMainWrapperVerticalPadding = currentStep === 'AgreementStep';

    const goToLoginPage = () => {
        stepsEvents.setStep(Steps.BullzLogin);
    };

    const goToStart = () => stepsEvents.setStep(Steps.VideoRecord);

    return (
        <Formik {...registrationInitialProps}>
            <StepPageWrapper>
                {/* <LoaderModal /> */}
                <SuccessModal withBackgroundImage onClick={goToStart} />
                <TopBar onButtonClick={goToPrevStep} />
                <MainWrapper noVerticalPadding={noMainWrapperVerticalPadding}>
                    <CurrentComponent setCurrentStep={setCurrentStep} />
                </MainWrapper>
                <InformationFooter information="Already have an account?">
                    <LinkButton onClick={goToLoginPage}>Log in</LinkButton>
                </InformationFooter>
            </StepPageWrapper>
        </Formik>
    );
};
