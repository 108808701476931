import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { PasswordField } from 'components/Formik/PasswordField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import React, { FC, FormEvent } from 'react';
import { bullzSignEffects } from 'stores/bullzStores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { BullzSignStepProps } from 'types/sign';

const { setNewPassword } = bullzSignEffects;
const { setStep } = stepsEvents;

export const NewPasswordStep: FC<BullzSignStepProps> = () => {
    const isLoading = useStore(setNewPassword.pending);
    const [, { error: passwordError, value: newPassword }] = useField('password');
    const [, { value: smsCode }] = useField('smsCode');
    const [, { value: phoneNumber }] = useField('phone');
    const [, { value: countryCode }] = useField('countryCode');

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (passwordError) {
            return;
        }

        await setNewPassword({ newPassword, validationCode: smsCode, mobileNumber: countryCode + phoneNumber });

        setStep(Steps.BullzLogin);
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    return (
        <Form onSubmit={onSubmit}>
            <Column alignCenter>
                <TitleBlock subtitle="Please enter your new password." title="Set New Password" />

                <Section justifyCenter marginBottom="25px" marginTop="52px">
                    <PasswordField />
                </Section>
                <Button disabled={!!passwordError} isLoading={isLoading}>
                    Next
                </Button>
            </Column>
        </Form>
    );
};
