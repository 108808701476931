import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { PhoneField } from 'components/Formik/PhoneField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import React, { FC, FormEvent } from 'react';
import { bullzSignEffects } from 'stores/bullzStores/sign';
import { BullzSignStepProps } from 'types/sign';

const { sendForgottenPasswordSmsCode, analyzeMobileNumber } = bullzSignEffects;

export const PhoneStep: FC<BullzSignStepProps> = ({ setCurrentStep }) => {
    const isAnalyzePending = useStore(analyzeMobileNumber.pending);
    const [, { value: phoneNumber, error: phoneNumberError }] = useField('phone');
    const [, { value: countryCode, error: countryCodeError }] = useField('countryCode');

    const error = !!(countryCodeError || phoneNumberError);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (error) {
            return;
        }

        sendForgottenPasswordSmsCode(countryCode + phoneNumber);

        setCurrentStep('SmsCodeStep');
    };

    // useEffect(() => {
    //     isAnalyzePending ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isAnalyzePending]);

    return (
        <Form onSubmit={onSubmit}>
            <Column alignCenter>
                <TitleBlock subtitle="Please enter your registered Phone Number." title="Forgot Password?" />
                <Section marginBottom="40px" marginTop="120px">
                    <PhoneField />
                </Section>
                <Button disabled={error} isLoading={isAnalyzePending}>
                    Send Security Code
                </Button>
            </Column>
        </Form>
    );
};
