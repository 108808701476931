import { CustomImage } from 'components/common/CustomImg/styles';
import { checkboxBorderRadius, checkboxDiameter } from 'components/common/Input/Checkbox/constants';
import { CheckboxProps } from 'components/common/Input/Checkbox/types';
import { grey, violet } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

export const Label = styled.label`
    ${flexCenter};
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const VisibleCheckbox = styled.div<CheckboxProps>`
    position: relative;
    width: ${checkboxDiameter};
    height: ${checkboxDiameter};
    background: ${({ checked, backgroundColor }) => (checked ? (backgroundColor ? backgroundColor : violet) : grey)};
    border-radius: ${checkboxBorderRadius};
    transition: 0.3s;
    cursor: pointer;

    ${({ disabled }) => disabled && `cursor: not-allowed; opacity: 0.25;`};
    ${CustomImage} {
        visibility: ${({ checked, disabled }) => (checked || disabled ? 'visible' : 'hidden')};
    }
`;
