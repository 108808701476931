import bullzLogo from 'assets/bullz_logo_white.svg';
import { Button } from 'components/common/buttons/Button';
import { CustomImage } from 'components/common/CustomImg/styles';
import { Span } from 'components/common/typography/Span';
import { LoaderModal } from 'components/modals/LoaderModal';
import { Column, Row, Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { black, violet2, white } from 'constants/styles/colors';
import {
    buttonFontSize,
    buttonFontWeight,
    buttonLineHeight,
    buttonMarginBottom,
    buttonWidth,
    titleFontSize,
    titleFontWeight,
    titleLineHeight
} from 'pages/BullzPages/Sign/Welcome/constants';
import React from 'react';
import { bullzSignEffects } from 'stores/bullzStores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { WelcomeWrapper } from './styles';

const { setStep } = stepsEvents;
const { authorizeWithoutAccount } = bullzSignEffects;

export const BullzWelcomeUserPage = () => {
    const goToLoginPage = () => setStep(Steps.BullzLogin);
    const goToRegistrationPage = () => setStep(Steps.BullzRegistration);

    const goToUploadWithoutAccount = () => authorizeWithoutAccount({});

    return (
        <WelcomeWrapper>
            <LoaderModal />
            <Section alignContentBetween height="100%">
                <Column marginBottom="20px">
                    <MarginWrapper marginBottom="32px">
                        <CustomImage height="auto" src={bullzLogo} width="130px" />
                    </MarginWrapper>

                    <Span
                        color={white}
                        fontSize={titleFontSize}
                        fontWeight={titleFontWeight}
                        lineHeight={titleLineHeight}
                    >
                        The Ultimate Source
                    </Span>

                    <MarginWrapper marginBottom="14px">
                        <Span
                            color={white}
                            fontSize={titleFontSize}
                            fontWeight={titleFontWeight}
                            lineHeight={titleLineHeight}
                        >
                            for&nbsp;
                        </Span>
                        <Span
                            color={violet2}
                            fontSize={titleFontSize}
                            fontWeight={titleFontWeight}
                            lineHeight={titleLineHeight}
                        >
                            Everything Crypto
                        </Span>
                    </MarginWrapper>

                    <Row maxWidth="275px">
                        <Span color={white} fontSize="16px" lineHeight="28px">
                            Join industry experts, passionate communities, and honest creators and&nbsp;get rewarded
                        </Span>
                    </Row>
                </Column>

                <Column alignCenter justifyCenter width="100%">
                    <MarginWrapper marginBottom={buttonMarginBottom}>
                        <Button backgroundColor={white} width={buttonWidth} onClick={goToRegistrationPage}>
                            <Span fontSize={buttonFontSize} fontWeight={buttonFontWeight} lineHeight={buttonLineHeight}>
                                Sign up
                            </Span>
                        </Button>
                    </MarginWrapper>

                    <MarginWrapper marginBottom={buttonMarginBottom}>
                        <Button backgroundColor={black} borderColor={white} width={buttonWidth} onClick={goToLoginPage}>
                            <Span
                                color={white}
                                fontSize={buttonFontSize}
                                fontWeight={buttonFontWeight}
                                lineHeight={buttonLineHeight}
                            >
                                Log in
                            </Span>
                        </Button>
                    </MarginWrapper>

                    <Button
                        backgroundColor="transparent"
                        borderColor={white}
                        width={buttonWidth}
                        onClick={goToUploadWithoutAccount}
                    >
                        <Span
                            color={white}
                            fontSize={buttonFontSize}
                            fontWeight={buttonFontWeight}
                            lineHeight={buttonLineHeight}
                        >
                            Continue without account
                        </Span>
                    </Button>
                </Column>
            </Section>
        </WelcomeWrapper>
    );
};
