import { noop } from 'constants/functions';
import {
    validateLoginForm,
    validatePasswordForgotten,
    validateRegistrationForm
} from 'pages/BullzPages/Sign/bullzValidation';
import { getUserCountryId } from 'utils/getUserCountryId';

export interface LoginFormValues {
    countryCode: string;
    phone: string;
    username: string;
    smsCode: string;
    password: string;
}
export const registrationInitialProps = {
    validateOnChange: true,
    initialValues: {
        username: '',
        countryCode: '',
        countryId: getUserCountryId() || 'us',
        phone: '',
        smsVerificationCode: '',
        password: '',
        language: ''
    },
    validate: validateRegistrationForm,
    onSubmit: () => {}
};

export const loginInitialProps = {
    validateOnChange: true,
    initialValues: {
        countryCode: '',
        countryId: getUserCountryId() || 'us',
        phone: '',
        smsCode: '',
        password: ''
    },

    validate: validateLoginForm,
    onSubmit: noop
};

export const passwordForgottenInitialProps = {
    validateOnChange: true,

    initialValues: {
        phone: '',
        countryCode: '',
        countryId: getUserCountryId() || 'us',
        password: '',
        smsCode: ''
    },
    validate: validatePasswordForgotten,
    onSubmit: () => {}
};
