import { requiredFieldMessage } from 'constants/messages';
import { FormikErrors, FormikValues } from 'formik';

export const emptyFieldErrorMessage = 'fill the field';

interface FormErrors {
    categoryTagId?: string;
    brand?: string;
    product?: string;
    productLink?: string;
}

//
/*** Brand ***/
//
const validateCategory = (category: string) => (category?.length ? '' : requiredFieldMessage);

//
/*** Brand ***/
//
const validateBrandName = (name: string) => (name?.length ? '' : requiredFieldMessage);

//
/*** Product ***/
//
const validateProductName = (name: string) => (name?.length ? '' : requiredFieldMessage);

//
/*** ProductLink ***/

const validateProductLink = (url: string) => {
    // const isSecureProtocol = url?.toLowerCase().startsWith('https://');
    // const isMinLength = url?.length > 8;

    if (!url?.length) return requiredFieldMessage;

    // if (!(isSecureProtocol && isMinLength)) return invalidUrlFormatMessage;
};

export const validateUploadForm = ({ brand, product, productLink, categoryTagId }: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.categoryTagId = validateCategory(categoryTagId);
    errors.brand = validateBrandName(brand);
    errors.product = validateProductName(product);
    errors.productLink = validateProductLink(productLink);

    //console.log('VALIDATION_errors___', errors);

    return errors;
};
