import { InputProps } from 'components/common/Input';
import { InputWrapper, Label, VisibleCodeInput, Wrapper } from 'components/common/Input/CodeInput/styles';
import { CodeInputProps } from 'components/common/Input/CodeInput/types';
import { HiddenInput } from 'components/common/Input/HiddenInput';
import { InputInformationSpan } from 'components/common/Input/StyledInput/styles';
import { Column, Row, Section } from 'components/wrappers/FlexWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { noop } from 'constants/functions';
import { black, errorColor, violet } from 'constants/styles/colors';
import React, { ChangeEvent, FocusEvent, useState } from 'react';
import { IsError } from 'types/forms';
import { formatInput } from 'utils/usefulFunctions';

interface Props extends CodeInputProps, InputProps, IsError {
    name: string;
    codeLength?: number;
    // errorText?: string;
    // isValid?: boolean;
    error?: string;
    touched: boolean;
}

export const CodeInput = ({
    error,
    touched,
    codeLength = 6,
    onBlur = noop,
    onChange,
    // errorText,
    // isValid,
    // isFocused,
    ...inputProps
}: Props) => {
    const [inputValue, setInputValue] = useState(inputProps?.value || '');
    const [isFocused, setIsFocused] = useState(false);
    const color = inputValue.length === 6 && !!error ? errorColor : undefined;

    const setBorderColor = (codeSymbol: string) => {
        let borderColor = undefined;

        if (touched && error) {
            borderColor = errorColor;
        } else if (codeSymbol) {
            borderColor = violet;
        } else if (isFocused) {
            borderColor = black;
        }

        return borderColor;
    };

    const codeArray = formatInput(inputValue, codeLength);

    const onInputFocus = () => {
        setIsFocused(true);
    };
    const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur(e);
    };

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        setInputValue(inputValue);
        onChange(e);
    };

    return (
        <Wrapper>
            <Label>
                <Column>
                    <RelativeWrapper>
                        <Section justifyCenter>
                            <InputWrapper>
                                {codeArray.map((codeSymbol, index) => (
                                    <VisibleCodeInput
                                        key={codeSymbol + `${index}`}
                                        borderColor={setBorderColor(codeSymbol)}
                                        color={color}
                                    >
                                        {codeSymbol}
                                    </VisibleCodeInput>
                                ))}
                            </InputWrapper>

                            <HiddenInput
                                autoFocus
                                autoComplete="off"
                                maxLength={codeLength}
                                type="text"
                                onBlur={onInputBlur}
                                onChange={onInputChange}
                                onFocus={onInputFocus}
                                {...inputProps}
                            />
                        </Section>
                    </RelativeWrapper>
                    <Row justifyCenter maxWidth="300px">
                        <InputInformationSpan error={error}>{touched && error}</InputInformationSpan>
                    </Row>
                </Column>
            </Label>
        </Wrapper>
    );
};
