import { StyledInputWrapper } from 'components/common/Input/WrappedInput/styles';
import { white } from 'constants/styles/colors';
import styled from 'styled-components';

interface MainWrapperProps {
    noVerticalPadding?: boolean;
}

export const MainWrapper = styled.section<MainWrapperProps>`
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 400px) {
        padding: 25px;
    }
`;

export const StepPageWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    // height: calc(100% - 60px);
    height: 100%;
    width: 100%;
    background-color: ${white};
`;

export const InputsWrapper = styled.div`
    min-height: 165px;
    ${StyledInputWrapper}:not(:last-child) {
        margin-bottom: 14px;
    }
`;

export const NextButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 35px 0 28px;
`;
