import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const StyledModalWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);

    ${flexCenter};
    z-index: 100;
`;

export const StyledModalTransparentWrapper = styled(StyledModalWrapper)`
    background: transparent;
    pointer-events: none;
`;
