import questionIcon from 'assets/icons/question_icon.svg';
import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { CustomImg } from 'components/common/CustomImg';
import { BooleanCheckbox } from 'components/common/Input/Checkbox';
import { PhoneField } from 'components/Formik/PhoneField';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { violet } from 'constants/styles/colors';
import { questionIconDiameter } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import { useToggle } from 'hooks';
import { bullzPrivacyLink, bullzTermsLink } from 'pages/BullzPages/Sign/Registration/Steps/Phone/constants';
import React, { FC, FormEvent } from 'react';
import { bullzSignEffects } from 'stores/bullzStores/sign';
import { BullzSignStepProps } from 'types/sign';
import { AgreementSpan, AgreementWrapper, Link } from './styles';

const { sendSmsCode, analyzeMobileNumber } = bullzSignEffects;

export const PhoneStep: FC<BullzSignStepProps> = ({ setCurrentStep }) => {
    const [isAgree, toggleIsAgree] = useToggle();
    const isSmsCodePending = useStore(sendSmsCode.pending);
    const isAnalyzePhonePending = useStore(analyzeMobileNumber.pending);
    const isLoading = isSmsCodePending || isAnalyzePhonePending;

    const [, { value: countryCode }] = useField('countryCode');
    const [, { value: phoneNumber, error: phoneNumberError }] = useField('phone');
    const isDisabled = !(isAgree && !phoneNumberError);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        // * If .then used instead of .finally,
        // * 409 response throws error and setCurrentStep isn't fired
        sendSmsCode(countryCode + phoneNumber).finally(() => {
            setCurrentStep('SmsCodeStep');
        });
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    return (
        <Form onSubmit={handleFormSubmit}>
            <AbsoluteWrapper right="25px" top="70px">
                <CustomImg height={questionIconDiameter} src={questionIcon} width={questionIconDiameter} />
            </AbsoluteWrapper>
            <MarginWrapper marginBottom="105px">
                <TitleBlock
                    subtitle="Create a profile, discover and share what’s new in crypto, and earn rewards."
                    title="Sign Up"
                />
            </MarginWrapper>

            <Column width="100%">
                <PhoneField key="Phone" borderColor={violet} />
            </Column>

            <Section justifyCenter marginBottom="32px" marginTop="40px">
                <Button disabled={isDisabled} height="48px" isLoading={isLoading} type="submit">
                    Send Code
                </Button>
            </Section>
            <AgreementWrapper justifyCenter noWrap>
                <MarginWrapper marginRight="8px">
                    <BooleanCheckbox checked={isAgree} name="agreement" onChange={() => toggleIsAgree()} />
                </MarginWrapper>
                <AgreementSpan>
                    By continuing, you agree to the BULLZ{' '}
                    <Link href={bullzTermsLink} target="_blank">
                        Terms&nbsp;and&nbsp;Conditions
                    </Link>{' '}
                    and confirm that you have read the BULLZ{' '}
                    <Link href={bullzPrivacyLink} target="_blank">
                        Privacy&nbsp;Policy
                    </Link>
                    . SMS fees may apply.
                </AgreementSpan>
            </AgreementWrapper>
        </Form>
    );
};
