import { ResetTimer } from 'components/common/blocks/ResetTimer';
import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { CodeField } from 'components/Formik/SmsCodeField';
import { Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { Form, useField } from 'formik';
import { useEffectsPendingStatus } from 'hooks';
import React, { FC, FormEvent } from 'react';
import { sendSmsForgottenPassword } from 'services/bullzServices/sign';
import { bullzSignEffects } from 'stores/bullzStores/sign';
import { BullzSignStepProps } from 'types/sign';

const { sendSmsCode, checkSmsCode } = bullzSignEffects;

export const SmsCodeStep: FC<BullzSignStepProps> = ({ setCurrentStep }) => {
    const [, /*{ value: smsCodeValue }*/ { error: smsCodeError }, smsCodeHelpers] = useField('smsCode');
    const [{ value: phoneNumber } /*{ error: phoneNumberError }*/] = useField('phone');
    const [{ value: countryCode } /*{ error: countryCodeError }*/] = useField('countryCode');

    const isCheckPending = useEffectsPendingStatus(checkSmsCode);
    const isSendPending = useEffectsPendingStatus(sendSmsCode);
    const isLoading = isCheckPending || isSendPending;

    const sendNewCode = async () => {
        await sendSmsForgottenPassword(countryCode + phoneNumber);

        smsCodeHelpers.setValue('');
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (smsCodeError) {
            return;
        }

        setCurrentStep('NewPasswordStep');
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    return (
        <Form onSubmit={handleFormSubmit}>
            <MarginWrapper marginBottom="50px">
                <TitleBlock
                    secure={phoneNumber}
                    subtitle="We sent a code to your phone number"
                    title="Enter 6 Digit Code"
                />
            </MarginWrapper>
            <Section>
                <CodeField name="smsCode" />
            </Section>
            <Section justifyCenter marginBottom="33px" marginTop="40px">
                <Button disabled={!!smsCodeError} height="48px" isLoading={isLoading} type="submit">
                    Next
                </Button>
            </Section>
            <Section justifyCenter>
                <ResetTimer timer={120} onAction={sendNewCode}>
                    Resend code
                </ResetTimer>
            </Section>
        </Form>
    );
};
