//import backArrow from 'assets/icons/back_arrow.svg';
import circleBackArrow from 'assets/icons/circle_back_arrow.svg';
import circleForwardArrow from 'assets/icons/circle_forward_arrow.svg';
import preloaderSvgWhite from 'assets/preloader-white.svg';
import { violet } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

export interface StyledNavigationButtonProps {
    button?: boolean | string;
    disabled?: boolean;
    isLoading?: boolean;
}

export const StyledNavigationButton = styled.button<StyledNavigationButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${({ button, disabled, isLoading }) => css`
        background-image: url(${circleBackArrow});
        background-repeat: no-repeat;
        background-position: center;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background-color: rgba(140, 137, 137, 0.58);
        cursor: pointer;
        ${button &&
        css`
            background-image: url(${isLoading ? preloaderSvgWhite : circleForwardArrow});
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background-color: ${violet};
            //background-size: ${isLoading ? '20px' : '16px'};
        `}
        ${disabled &&
        css`
            background-color: #d8d8d8;
            cursor: not-allowed;
        `}
    `};
`;
