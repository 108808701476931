import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { apiVersion, bullzBaseURL } from 'constants/stores';
import { bullzStores } from 'stores/bullzStores/user';

const bullzAxiosInstance = axios.create();

bullzAxiosInstance.defaults.baseURL = bullzBaseURL();
bullzAxiosInstance.defaults.method = 'POST';
bullzAxiosInstance.defaults.headers['api-version'] = apiVersion;
bullzAxiosInstance.interceptors.response.use(
    config => config.data,
    config => Promise.reject(config?.response?.data ?? config)
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = bullzAxiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${bullzStores.token.getState() || process.env.REACT_APP_AUTH_TOKEN}`,
                  ...config.headers
              }
            : { ...config.headers }
    });

    return (request as any) as Promise<T>;
};
