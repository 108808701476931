import { createEffect, createEvent, createStore } from 'effector';
import { BullzAPI } from 'services/bullzServices';
import { bullzProductEffects } from 'stores/bullzStores/product';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { Steps, stepsEvents } from 'stores/steps';
import { createNotifyingEffect } from 'utils/common';

//
/*** Local store ***/
//
const setRecorderVideo = createEvent<Blob | null>();
const setRecorderVideoScreen = createEvent<string>();
const setRecorderVideoDuration = createEvent<number>();
const setRecorderVideoDate = createEvent<string>();
const setErrors = createEvent<boolean>();

const recorderVideo = createStore<Blob | null>(null).on(setRecorderVideo, (_, video) => video);
const recorderVideoScreen = createStore<string>('').on(setRecorderVideoScreen, (_, screen) => screen);
const recorderVideoDuration = createStore<number>(0).on(setRecorderVideoDuration, (_, duration) => duration);
const recorderVideoDate = createStore<string>('').on(setRecorderVideoDate, (_, date) => date);

const updateNewVideoRequestValues = createEvent<BULLZ.CreateVideoRequest>();
const newVideoRequestValues = createStore<BULLZ.CreateVideoRequest>({})
    .on(updateNewVideoRequestValues, (state, newState) => ({
        ...state,
        ...newState
    }))
    .on(bullzProductEffects.initializeRemoteProduct.doneData, (state, { productId }) => ({ ...state, productId }));

const invokeNewVideoRequest = createEffect({
    handler: async () => {
        try {
            const videoParameters = newVideoRequestValues.getState();
            const response = await BullzAPI.video.newRequest(videoParameters);

            return response;
        } catch (error) {
            let message = error.message;

            if (!message || message === 'unknown') {
                message = 'Some error occurred. Please try again.';
            }
            modalEvents.openAsyncModal({
                title: 'Error',
                errorText: message,
                onOk: () => stepsEvents.setStep(Steps.BullzWelcome)
            });

            setErrors(true);
            return {};
        }
    }
});

const videoErrors = createStore(false).on(setErrors, (_, newState) => newState);

//
/*** API effects ***/
//
const createNewVideoRequest = createNotifyingEffect({
    handler: async (data: BULLZ.CreateVideoRequest) => await BullzAPI.video.newRequest(data)
});

const uploadVideo = createNotifyingEffect({
    handler: async (data: { videoId: string; videoStream: Blob }) =>
        await BullzAPI.media.upload(data.videoId, data.videoStream)
});

const validateContent = createNotifyingEffect({
    handler: async (data: BULLZ.WOMStartValidationRequest) => await BullzAPI.wom.beginValidation(data)
});

//
/*** Return ***/
//
const bullzVideoEvents = {
    updateNewVideoRequestValues,
    setRecorderVideo,
    setRecorderVideoScreen,
    setRecorderVideoDuration,
    setRecorderVideoDate
};
const bullzVideoEffects = {
    createNewVideoRequest,
    uploadVideo,
    validateContent,
    invokeNewVideoRequest
};

const bullzVideoStores = {
    recorderVideo,
    recorderVideoScreen,
    recorderVideoDuration,
    recorderVideoDate,
    newVideoRequestValues,
    videoErrors
};

export { bullzVideoEvents, bullzVideoStores, bullzVideoEffects };
