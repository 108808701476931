import { InformationFooter } from 'components/common/blocks/RegistrationFooter';
import { LinkButton } from 'components/common/buttons/LinkButton';
import { TopBar } from 'components/common/TopBar';
import { Column } from 'components/wrappers/FlexWrapper';
import { Formik } from 'formik';
import { useSteps } from 'hooks';
import { loginInitialProps } from 'pages/BullzPages/Sign/bullzFormikInitialProps';
import { MainWrapper, StepPageWrapper } from 'pages/BullzPages/Sign/styles';
import React, { useEffect } from 'react';
import { bullzSignEvents } from 'stores/bullzStores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { BullzLoginStepName, BullzLoginStepsObject, BullzSignStepProps } from 'types/sign';
import { PhoneOrUsernameStep } from './Steps/PhoneOrUsernameStep';

const { changeChosenSignInput } = bullzSignEvents;

const stepsObj: BullzLoginStepsObject = {
    PhoneOrUsernameStep
};

export const BullzLoginPage = () => {
    const [CurrentComponent, setCurrentStep, goToPrevStep] = useSteps<BullzLoginStepName, BullzSignStepProps>(stepsObj);

    // Unmount cleanup
    useEffect(
        () => () => {
            changeChosenSignInput('Phone');
        },
        []
    );

    const goToRegistrationPage = () => {
        stepsEvents.setStep(Steps.BullzRegistration);
    };

    return (
        <Formik {...loginInitialProps}>
            <StepPageWrapper>
                {/* <LoaderModal /> */}
                <Column alignCenter width="100%">
                    <TopBar onButtonClick={goToPrevStep} />
                    <MainWrapper>
                        <CurrentComponent setCurrentStep={setCurrentStep} />
                    </MainWrapper>
                </Column>
                <InformationFooter information="Don’t have an account?">
                    <LinkButton onClick={goToRegistrationPage}>Sign Up</LinkButton>
                </InformationFooter>
            </StepPageWrapper>
        </Formik>
    );
};
