import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { PasswordField } from 'components/Formik/PasswordField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { Form, useField } from 'formik';
import React, { FC, FormEvent } from 'react';
import { BullzSignStepProps } from 'types/sign';

export const PasswordStep: FC<BullzSignStepProps> = ({ setCurrentStep }) => {
    const [, { error }] = useField('password');

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (error) {
            return;
        }

        setCurrentStep('AgreementStep');
    };

    return (
        <Form onSubmit={handleFormSubmit}>
            <Column alignCenter>
                <TitleBlock title="Set Password" />
                <Section justifyCenter marginBottom="25px" marginTop="80px">
                    <PasswordField />
                </Section>

                <Button disabled={!!error}>Next</Button>
            </Column>
        </Form>
    );
};
