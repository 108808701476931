import { white } from 'constants/styles/colors';
import styled, { css } from 'styled-components';

export const VideoInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
`;

export const Title = styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    margin-bottom: 30px;
`;

interface MainWrapperProps {
    isFinalStep: boolean;
}

export const MainWrapper = styled.section<MainWrapperProps>`
    ${({ isFinalStep }) => css`
        min-height: ${isFinalStep ? '100%' : 'calc(100% - 60px)'};
        padding: ${isFinalStep ? '0' : '28px 20px'};
        display: flex;
        flex-direction: column;
        background-color: ${white};

        ${isFinalStep &&
        css`
            ${VideoInfoWrapper} {
                display: none;
            }
        `}
    `}
`;

interface VideoPosterProps {
    poster: string;
}

export const VideoPoster = styled.div<VideoPosterProps>`
    ${props => css`
        width: 70px;
        height: 119px;
        margin-right: 17px;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        background-image: url(${props.poster});
    `}
`;

export const VideoName = styled.b`
    font-size: 15px;
    font-weight: 800;
`;

export const VideoDurationText = styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    opacity: 0.4;
`;

export const VideoDurationTextBlock = styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
`;

export const StepTitle = styled.h2`
    font-size: 18px;
`;

interface StepWrapperProps {
    paddingTop: number;
}

export const StepWrapper = styled.div<StepWrapperProps>`
    ${props => css`
        min-height: 192px;
        padding-top: ${props.paddingTop}px;
        margin-bottom: 28px;
    `}
`;
