import { createEvent, restore } from 'effector';
import { bullzDevBaseURL, womDevBaseURL } from '../constants/services';

const setWOMBaseURL = createEvent<string>();
const womBaseURL = restore(setWOMBaseURL, womDevBaseURL);

const setBULLZBaseURL = createEvent<string>();
const bullzBaseURL = restore(setBULLZBaseURL, bullzDevBaseURL);

const setApplicationProperties = createEvent<[number, number]>();
const applicationProperties = restore<[number, number]>(setApplicationProperties, [0, 0]);

export const globalStores = { bullzBaseURL, womBaseURL, applicationProperties };
export const globalEvents = { setBULLZBaseURL, setWOMBaseURL, setApplicationProperties };
