/**
 * Util to detect mobile device
 */
// const isMobileDevice = () =>
//     typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
console.log('navigator.userAgent', navigator.userAgent);
// TODO: detect IOS https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

// eslint-disable-next-line no-useless-escape
export const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
export const isMobile = !!navigator.userAgent.match(/.*Mobile/);

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
