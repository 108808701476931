import { womApiVersion, womBaseURL } from '../../constants/stores';
import axios from '../axios';

export const getWallet = (data: WOM.WalletGetRequest) =>
    axios<WOM.WalletResponse>({
        url: '/wallet/get',
        baseURL: womBaseURL(),
        data,
        headers: {
            'api-version': womApiVersion
        }
    });
