import { validateUploadForm } from 'pages/BullzPages/Upload/validation';

const initialValues: BULLZ.CreateVideoRequest = {
    categoryTagId: '',
    brand: '',
    product: '',
    productId: '',
    spokenLanguages: [],
    audioLanguages: [],
    hashTags: [],
    isExclusive: false,
    productLink: ''
};

export const uploadInitialProps = {
    initialValues,
    // initialTouched: {
    //     brand: true,
    //     product: true,
    //     productLink: true
    // },
    initialErrors: {},

    validate: validateUploadForm,
    onSubmit: () => {}
};
