import { bullzBaseURL } from 'constants/stores';
import axios from 'services/axios';

export const checkUsernameExistence = (data: BULLZ.UsernameExistsRequest) =>
    axios<BULLZ.ExistenceResponse>({
        url: '/user/username-exists',
        baseURL: bullzBaseURL(),
        data
    });

export const analyzeMobileNumber = (data: BULLZ.AnalyzeMobileNumberRequest) =>
    axios<BULLZ.AnalyzeMobileNumberResponse>({
        url: '/user/sms-analyze-mobile-number',
        baseURL: bullzBaseURL(),
        data
    });

export const sendVerificationSms = async (data: BULLZ.SendVerificationSmsRequest) =>
    axios<BULLZ.SendVerificationSmsResponse>({
        url: '/user/sms-send-verification',
        baseURL: bullzBaseURL(),
        data
    });

export const checkSmsCode = (data: BULLZ.CheckSmsCodeRequest) =>
    axios<BULLZ.CheckSmsCodeResponse>({
        url: '/user/sms-check-verification-code',
        baseURL: bullzBaseURL(),
        data
    });

export const createAccount = (data: BULLZ.UserCreateAccountRequest) =>
    axios<BULLZ.UserJwtTokenResponse>({
        url: '/user/create-account',
        baseURL: bullzBaseURL(),
        data
    });

export const authenticateUser = (data: BULLZ.UserAuthChallengeEmailOrUsernameOrPhoneRequest) =>
    axios<BULLZ.UserJwtTokenResponse>({
        url: '/user/authenticate',
        baseURL: bullzBaseURL(),
        data
    });

export const getCurrentAuthorization = (data: BULLZ.AuthorizationRequest) =>
    axios<BULLZ.AuthorizationDetailResponse>({
        url: '/user/get-current-authorization',
        baseURL: bullzBaseURL(),
        data
    });

export const sendValidationLinkEmail = (data: BULLZ.UserWantsValidationLinkEmailRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/send-validation-link-email',
        baseURL: bullzBaseURL(),
        data
    });

export const sendSmsForgottenPassword = (data: BULLZ.SendForgottenPasswordSmsRequest) =>
    axios<BULLZ.SendForgottenPasswordSmsResponse>({
        url: '/user/sms-send-forgotten-password',
        baseURL: bullzBaseURL(),
        data
    });

export const authenticateUserWithoutAccount = (data: BULLZ.AuthenticateWebRecorderRequest) =>
    axios<BULLZ.UserJwtTokenResponse>({
        url: '/user/authenticate-webrecorder',
        baseURL: bullzBaseURL(),
        data
    });

export const setNewPassword = (data: BULLZ.ChangeUserPasswordViaSmsRequest) =>
    axios<BULLZ.ChangeUserPasswordViaSmsResponse>({
        url: '/user/sms-change-user-password',
        baseURL: bullzBaseURL(),
        data
    });
