import { Button } from 'components/common/buttons/Button';
import styled, { css } from 'styled-components';
import { TextProperties } from 'types/styles';

interface MainWrapperProps {
    poster?: string;
}

export const MainWrapper = styled.section<MainWrapperProps>`
    ${({ poster }) => css`
        padding: 57px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #000000 url(${poster}) no-repeat center;
        background-size: cover;
        position: relative;
        flex-grow: 1;

        * {
            position: relative;
        }
    `}
`;

export const ContentWrapper = styled.div`
    max-width: 350px;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ButtonsWrapper = styled(ContentWrapper)`
    margin-top: 60px;
`;

export const DarkenBg = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
`;

export const Title = styled.h1`
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
    letter-spacing: 1px;
`;

export const Text = styled.div<Pick<TextProperties, 'fontSize'>>`
    color: #fff;
    font-size: ${({ fontSize }) => fontSize || '16px'};
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 1px;
    opacity: 0.9;
`;

export const Qualities = styled.p`
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;

    p {
        margin: 0;
        margin-bottom: 15px;
    }

    p:last-child {
        margin: 0;
    }
`;

export const Stake = styled.div`
    width: 148px;
    height: 50px;
    margin: 11px 0 6px;
    flex: none;
    background: #fff;
    border-radius: 9px;
    line-height: 50px;
    font-size: 16px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 600;
`;

export const StyledButton = styled(Button)`
    width: 231px;
    margin-bottom: 15px;
`;

export const CancelButton = styled(Button)`
    margin-top: 5px;
    height: 21px;
    width: auto;
    border-radius: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border: none;
`;
