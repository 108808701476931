import { Layout } from 'components/Layout';
import { AsyncModal } from 'components/modals/AsyncModal';
import { UploadProgressModal } from 'components/modals/UploadProgressModal';
import { Notifications } from 'components/Notifications';
import { StepRoute } from 'components/StepRoute';
import { GlobalStyle } from 'constants/styles/global';
import 'fonts.css';
import { BullzLoginPage } from 'pages/BullzPages/Sign/Login';
import { BullzPasswordForgottenPage } from 'pages/BullzPages/Sign/PasswordForgotten';
import { BullzRegistrationPage } from 'pages/BullzPages/Sign/Registration';
import { BullzWelcomeUserPage } from 'pages/BullzPages/Sign/Welcome';
import { BullzThankYouPage } from 'pages/BullzPages/ThankYou';
import { BullzUploadPage } from 'pages/BullzPages/Upload';
import { EntryPage } from 'pages/Entry';
import { VideoRecordPage } from 'pages/VideoRecord';
import React, { useEffect } from 'react';
import { bullzProductEvents } from 'stores/bullzStores/product';
import { Steps } from 'stores/steps';
import { BUWRInitParameters } from 'types/plugin';
import { appVersion, bullzDevBaseURL, bullzProdBaseURL, womDevBaseURL, womProdBaseURL } from './constants/services';
import { globalEvents } from './stores/global';

const { setWOMBaseURL, setBULLZBaseURL } = globalEvents;
const { setInitialRemoteProductValues, setRemoteProductValues, setOrganizationPublicId } = bullzProductEvents;

const App = ({
    organizationPublicId,
    remoteProduct,
    isDev,
    ...homeStylesProps
}: Omit<BUWRInitParameters, 'selector'>) => {
    const product = remoteProduct?.name || '';

    useEffect(() => {
        setWOMBaseURL(isDev ? womDevBaseURL : womProdBaseURL);
        setBULLZBaseURL(isDev ? bullzDevBaseURL : bullzProdBaseURL);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        remoteProduct && setInitialRemoteProductValues(remoteProduct);
        remoteProduct && setRemoteProductValues(remoteProduct);
        organizationPublicId && setOrganizationPublicId(organizationPublicId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GlobalStyle />
            <Layout className={`bullz-web-recorder-plugin  ${appVersion} ${isDev ? 'DEV' : ''}`} id={product}>
                <UploadProgressModal />
                <AsyncModal />
                <Notifications />
                {/* <LoaderModal /> */}

                <StepRoute step={Steps.Entry}>
                    <EntryPage {...homeStylesProps} />
                </StepRoute>
                <StepRoute step={Steps.VideoRecord}>
                    <VideoRecordPage />
                </StepRoute>

                <StepRoute step={Steps.BullzWelcome}>
                    <BullzWelcomeUserPage />
                </StepRoute>

                <StepRoute step={Steps.BullzLogin}>
                    <BullzLoginPage />
                </StepRoute>

                <StepRoute step={Steps.BullzPasswordForgotten}>
                    <BullzPasswordForgottenPage />
                </StepRoute>

                <StepRoute step={Steps.BullzRegistration}>
                    <BullzRegistrationPage />
                </StepRoute>

                <StepRoute step={Steps.BullzUpload}>
                    <BullzUploadPage />
                </StepRoute>

                <StepRoute step={Steps.BullzThankYou}>
                    <BullzThankYouPage />
                </StepRoute>
            </Layout>
        </>
    );
};

export default App;
