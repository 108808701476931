import { womServices } from '../womServices';
import * as categories from './categories';
import * as media from './media';
import * as product from './product';
import * as sign from './sign';
import * as user from './user';
import * as video from './video';
import * as wom from './wom';

export const BullzAPI = {
    sign,
    video,
    wom,
    product,
    media,
    user,
    womServices,
    categories
};
