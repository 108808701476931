import App from 'App';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BUWRInitParameters } from 'types/plugin';

const rootElement = document.getElementById('bullz-plugin');

// if (process.env.NODE_ENV === 'production') {

window.bullz = {
    init: (params: BUWRInitParameters) => {
        const { selector, isDev, ...appParams } = params;
        const renderElement = selector ? document.querySelector(selector) : rootElement;

        if (renderElement) {
            ReactDOM.render(
                <StrictMode>
                    <App isDev={isDev} {...appParams} />
                </StrictMode>,
                renderElement
            );
        }
        console.log('Init params ', params);
    }
};
// } else {
//     // Mock data
//     const remoteProduct: WOM.RemoteProductDescription = {
//         name: 'Iphone 13 (TEST)',
//         brand: 'Apple',
//         publicId: 'WOMFORBULLZ123456789',
//         url:
//             'https://aliexpress.ru/item/4000622830703.html?algo_pvid=eaf5f4f8-f1d0-4b1c-877e-1e5326ba96be&algo_expid=eaf5f4f8-f1d0-4b1c-877e-1e5326ba96be-0&btsid=0b8b035616146836185221432e886e&ws_ab_test=searchweb0_0,searchweb201602_,searchweb201603_',
//         imageUrl: 'https://items.s1.citilink.ru/1192425_v01_b.jpg'
//     };
//     ReactDOM.render(
//         <StrictMode>
//             <App isDev organizationPublicId="INCODEWETRUST1_60950f88936aed152f526fc2" remoteProduct={remoteProduct} />
//         </StrictMode>,
//         rootElement
//     );
// }

let event = new Event('bullz-recorder-init', { bubbles: true });
document.body.dispatchEvent(event);
