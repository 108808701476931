import logoImage from 'assets/bullz_logo_white.svg';
import { Button } from 'components/common/buttons/Button';
import { Span } from 'components/common/typography/Span';
import { violet, white } from 'constants/styles/colors';
import { useRefWidthAndHeight } from 'hooks/getRefProperty';
import { Logo, PageWrapper, Title } from 'pages/Entry/styles';
import React, { useEffect, useRef } from 'react';
import { globalEvents } from 'stores/global';
import { Steps, stepsEvents } from 'stores/steps';
import { ApplicationInitialStyles } from 'types/plugin';

export const EntryPage = ({ background, logo, backgroundColor, color }: ApplicationInitialStyles) => {
    const goToNextStep = () => stepsEvents.setStep(Steps.VideoRecord);
    const applicationWrapperRef = useRef<HTMLDivElement>(null);
    const applicationWrapperProperties = useRefWidthAndHeight(applicationWrapperRef);

    useEffect(() => {
        globalEvents.setApplicationProperties(applicationWrapperProperties);
    }, [applicationWrapperProperties]);

    return (
        <PageWrapper ref={applicationWrapperRef} background={background}>
            <Logo src={logo || logoImage} />
            <Title>
                CREATE PRODUCT RECOMMENDATIONS <br /> AND EARN WOM
            </Title>
            <Button backgroundColor={backgroundColor || violet} height="57px" width="250px" onClick={goToNextStep}>
                <Span color={color || white} fontSize="14px" fontStyle="normal" fontWeight="700" letterSpacing="1px">
                    LET'S GO
                </Span>
            </Button>
        </PageWrapper>
    );
};
