import { DataPickerStyles } from 'components/common/Input/DateInput/styles';
import { scrollBarWidth } from 'constants/styles/sizes';
import { createGlobalStyle, css } from 'styled-components';
import { VideoJSStyles } from '../../components/common/VideoRecord/styles';

const ResettedStylesForApplication = css`
    * {
        box-sizing: border-box;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    background-color: white;
    overflow: auto;

    h1,
    h2,
    ul {
        margin: 0;
        padding: 0;
    }
`;

const ScrollBarStyle = css`
    ::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3); */
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: ${scrollBarWidth};
        height: ${scrollBarWidth};
        /* background-color: white; */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 4px;
    }
`;

const FontStyle = css`
    font-size: 16px;
    font-family: inherit;
    font-weight: 500;
`;

export const GlobalStyle = createGlobalStyle`
    #bullz-plugin {
        width: 100%;
        /* height: 700px; */
        height: 100vh;

      ${ResettedStylesForApplication};
      ${ScrollBarStyle};
      ${FontStyle};

    }
    .bullz-web-recorder-plugin{
        font-family: inherit;
    }

    ${ResettedStylesForApplication};
    ${ScrollBarStyle};
    ${FontStyle};

    ${VideoJSStyles}

    ${DataPickerStyles}
`;
